<template>
<transition name="fadePage" mode="out-in">
<div class="node_Ranking">
  <Top-Nav></Top-Nav>
  <div class="common_title">{{$t('menu.jdphb')}}</div>
  <div class="section_three">
    <div class="title"><span>{{$t('menu.jdpmcyzsl')}}</span><i>{{nodeCount}}</i></div>
  </div>
  <div class="node_table" :style="boxHeightObj">
    <div class="title_table">
      <span class="one">{{$t('menu.ranking')}}</span>
      <span class="two">{{$t('menu.address')}}</span>
      <span class="three">{{$t('menu.computationalPower')}}</span>
      <span class="four">{{$t('menu.cumulativeIncome')}}</span>
    </div>
    <div class="list_wrapper" :style="wrapHeightObj">
    <div class="list" v-for="(item,index) in tableData" :key="index">
      <span class="one">
        <img v-if="index==0&&tableData.length>=1" src="../../../public/images/icon1.png" alt="">
        <img v-if="index==1&&tableData.length>=2" src="../../../public/images/icon2.png" alt="">
        <img v-if="index==2&&tableData.length>=3" src="../../../public/images/icon3.png" alt="">
        <i v-if="index>2">{{item._range}}</i>
      </span>
      <span class="two" style="padding: 1rem 0;">{{item._accounts}}</span>
      <span class="three">{{formatAmountMethod(item._nodePows)}}</span>
      <span class="four">{{formatAmountMethod(item._nodeRewards)}}</span>
    </div>
    </div>
  </div>
</div>
</transition>
</template>
<script>
import '../../style/base.css';
import '../../style/nodeRanking.css';
import TopNav from '../../components/common/TopNav.vue';

const starPool = require('../../utils/starPool')

export default {
  name: 'NodeRanking',
  components:{TopNav},
  data() {
    return {
      formatAmountMethod:starPool.amountConversion,
      windowHeight: window.innerHeight,   //屏幕高度
      boxHeightObj:{  //背景框的高度
        height:0
      }, 
      wrapHeightObj:{  //背景框内容的高度
        height:0
      }, 
      tableData: [
      //   {
      //   _range:"1",
      //   _accounts:"0x68F4d",
      //   _nodeRewards:"2344",
      // },{
      //   _range:"1",
      //   _accounts:"0x68F4d",
      //   _nodeRewards:"2344",
      // },{
      //   _range:"1",
      //   _accounts:"0x68F4d",
      //   _nodeRewards:"2344",
      // }
      ],
      nodeCount:0,//节点排名参与总数量
    }
  },
  mounted(){
    //全网算力
    starPool.poolInfo().then(res=>{
      this.nodeCount = res._nodeCount
    })

    this.getData();
    this.boxHeightObj.height = (this.windowHeight/10)-5-7.8-3.2-4+'rem';
    this.wrapHeightObj.height = (this.windowHeight/10)-5-7.8-3.2-3.4-4+'rem';
  },
  methods:{
    getData(){
      let that = this
      starPool.nodeRanking().then(res=>{
        console.log(res)
        let tableData =[]
        let dataCount = res._accounts.length
        if(dataCount>0){
          for (let index = 0; index < dataCount; index++) {
            let _account = res._accounts[index]
            let data = {      
              _range:res._range[index], // 排名
              _accounts: _account.substr(0,5) + "..." + _account.substring(_account.length-4), // 地址
              _nodeRewards:res._nodeRewards[index], // 累计节点收益
              _nodePows:res._nodePows[index] // 算力
            }
            tableData.push(data)
          }
        }
        that.tableData = tableData
      })
     
    }
  }
}
</script>
<style scoped>
.section_three{
  height: 3rem;
  line-height: 3rem;
  margin-bottom: 1rem;
}
</style>